exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-belgeler-js": () => import("./../../../src/pages/belgeler.js" /* webpackChunkName: "component---src-pages-belgeler-js" */),
  "component---src-pages-evdeneve-js": () => import("./../../../src/pages/evdeneve.js" /* webpackChunkName: "component---src-pages-evdeneve-js" */),
  "component---src-pages-gizlilik-js": () => import("./../../../src/pages/gizlilik.js" /* webpackChunkName: "component---src-pages-gizlilik-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-processor-js": () => import("./../../../src/pages/processor.js" /* webpackChunkName: "component---src-pages-processor-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */)
}

